<template>
  <div>
    <h3>{{ $t("views.projects.add.title") }}</h3>

    <formular :isBusy="is.loading" :controlDefinition="controlDefinition" maxWidth :labelCols="4" showAbortButton @formular:abort="onAbort" @formular:submit="onSubmit" />
  </div>
</template>

<script>
import MixinEntityBase from '@/components/mixins/EntityBase'
import Formular from '@/components/form/Formular'

const ENTITY_BY_KEY = 'projects'

export default {
  name: 'Projects.Add',
  mixins: [MixinEntityBase],
  components: {
    Formular
  },
  computed: {
    is () {
      return this.$store.getters[`${this.eParent.key}/is`]
    },
    definition () {
      return this.$store.getters[`${this.eParent.key}/definition`]
    },
    entityBy () {
      return this.ePath.find((e) => e.key === ENTITY_BY_KEY)
    },
    controlDefinition () {
      return Object.assign({}, this.definition, {
        properties: this.definition.properties.map((p) => Object.assign({}, p, { settings: Object.assign({}, p.settings || {}, { labelCols: '4' }) }))
      })
    }
  },
  methods: {
    onAbort () {
      this.$router.push({ name: 'Projects' })
    },
    onSubmit (formData) {
      this.$store.dispatch(`${this.eParent.key}/createEntity`, Object.assign({ userId: this.entityBy.id }, formData.controls))
      this.$router.push({ name: 'Projects' })
    }
  },
  created () {
    this.$store.dispatch(`${this.eParent.key}/getDefinition`)
  }
}
</script>

<style lang="scss"></style>
